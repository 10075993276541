import React from 'react';
import Header from './components/Header';
import Hero from './components/Hero';
import About from './components/About';
import Projects from './components/Projects';
import Contact from './components/Contact';
import Footer from './components/Footer';
import { datadogRum } from '@datadog/browser-rum';
import { AwsRum } from 'aws-rum-web';
import TagManager from 'react-gtm-module'
const tagManagerArgs = {
  gtmId: 'GTM-NF83JL6M'
}
TagManager.initialize(tagManagerArgs)

datadogRum.init({
    applicationId: '94b71213-d624-4907-9893-4c2a7ad1cbe1',
    clientToken: 'pub19257d70a64a66d25473df53c691836e',
    // `site` refers to the Datadog site parameter of your organization
    // see https://docs.datadoghq.com/getting_started/site/
    site: 'us5.datadoghq.com',
    service: 'construtoramre',
    env: 'prod',
    // Specify a version number to identify the deployed version of your application in Datadog
    // version: '1.0.0', 
    sessionSampleRate: 100,
    sessionReplaySampleRate: 100,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: 'allow',
});


try {
  const config = {
    sessionSampleRate: 1,
    identityPoolId: "us-east-1:00c696b4-d34b-492d-961d-a283209079a0",
    endpoint: "https://dataplane.rum.us-east-1.amazonaws.com",
    telemetries: ["performance","errors","http"],
    allowCookies: true,
    enableXRay: true
  };

  const APPLICATION_ID = '9a813004-1724-4aa8-9566-3010fc4d853d';
  const APPLICATION_VERSION = '1.0.0';
  const APPLICATION_REGION = 'us-east-1';

  const awsRum = new AwsRum(
    APPLICATION_ID,
    APPLICATION_VERSION,
    APPLICATION_REGION,
    config
  );
} catch (error) {
  // Ignore errors thrown during CloudWatch RUM web client initialization
}


function App() {
  return (
    <div className="bg-gray-100">
      <Header />
      <Hero />
      <Projects />
      <About />
      <Contact />
      <Footer />
    </div>
  );
}

export default App;
