import React from 'react';

const Header = () => {
  return (
    <header className="bg-white shadow">
      <div className="container mx-auto px-4 py-4 flex justify-between items-center">
        <h1 className="text-2xl font-bold">Construtora MRE</h1>
        <nav>
          <a href="#about" className="text-gray-700 hover:text-gray-900 mx-4 text-lg">Sobre</a>
          <a href="#projects" className="text-gray-700 hover:text-gray-900 mx-4 text-lg">Projetos</a>
          <a href="#contact" className="text-gray-700 hover:text-gray-900 mx-4 text-lg">Contato</a>
        </nav>
      </div>
    </header>
  );
}

export default Header;
