import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';

const Hero = () => {
  const whatsappNumber = '+5551999311560';
  const message = 'Olá, Gostaria de saber mais sobre o serviço de reforma habitacional e o brinde';
  const whatsappURL = `https://wa.me/${whatsappNumber}?text=${encodeURIComponent(message)}`;


  return (
    <section 
      className="relative bg-cover bg-center min-h-screen flex items-center justify-center" 
      style={{ backgroundImage: "url('/images/hero.png')" }}
    >
      <div className="absolute inset-0 bg-black opacity-60"></div>
      <div className="container mx-auto px-4 relative z-10 text-center text-white">
        <h2 className="text-7xl font-bold mb-8">Construtora MRE</h2>
        <p className="text-4xl mb-12 max-w-2xl mx-auto">
          Transformando sonhos em realidade com qualidade, inovação e compromisso.
        </p>
      </div>
    </section>
  );
  
}

export default Hero;
