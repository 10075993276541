import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';

const Contact = () => {
  const [formData, setFormData] = useState({
    name: '',
    phone: '',
    message: ''
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const { name, phone, message } = formData;
    const whatsappNumber = '+5551999311560'; // Substitua pelo seu número de WhatsApp
    const textMessage = `Nome: ${name}%0AWhatsApp: ${phone}%0AMensagem: ${message}`;
    const whatsappURL = `https://wa.me/${whatsappNumber}?text=${textMessage}`;
    window.open(whatsappURL, '_blank');
  };

  return (
    <section id="contact" className="py-20 bg-white">
      <div className="container mx-auto px-4 text-center">
        <h2 className="text-3xl font-bold mb-4">Contato</h2>
        <p className="mb-8">Entre em contato conosco para discutir seu próximo projeto.</p>
        <form onSubmit={handleSubmit} className="max-w-lg mx-auto">
          <div className="mb-4">
            <input
              type="text"
              name="name"
              placeholder="Seu Nome"
              value={formData.name}
              onChange={handleChange}
              className="w-full p-3 rounded border border-gray-300"
              required
            />
          </div>
          <div className="mb-4">
            <input
              type="tel"
              name="phone"
              placeholder="Seu WhatsApp"
              value={formData.phone}
              onChange={handleChange}
              className="w-full p-3 rounded border border-gray-300"
              required
            />
          </div>
          <div className="mb-4">
            <textarea
              name="message"
              placeholder="Sua Mensagem"
              value={formData.message}
              onChange={handleChange}
              className="w-full p-3 rounded border border-gray-300"
              required
              rows="5"
            />
          </div>
          <button
            type="submit"
            className="bg-green-500 hover:bg-green-600 text-white font-semibold py-3 px-6 rounded shadow-lg transition duration-300 flex items-center justify-center"
          >
            <FontAwesomeIcon icon={faWhatsapp} className="mr-2" />
            Enviar Mensagem
          </button>
        </form>
      </div>
    </section>
  );
}

export default Contact;
