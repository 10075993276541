import React from 'react';

const Footer = () => {
  return (
    <footer className="bg-white py-6">
      <div className="container mx-auto px-4 text-center">
        <p className="text-gray-700">&copy; 2024 Construtora MRE. Todos os direitos reservados.</p>
        <p>CEO: Eduardo de Almeida Oliveira</p>
        <p>CNPJ: 46.707.229/0001-61</p>
      </div>
    </footer>
  );
}

export default Footer;
