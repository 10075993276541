import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Amplify } from 'aws-amplify';
import amplifyConfig from './amplifyconfiguration.json';
import awsExports from './aws-exports'; // Ensure this path is correct based on your setup
import { configureAutoTrack, record } from '@aws-amplify/analytics';
// Configure Amplify
Amplify.configure(awsExports);

configureAutoTrack({
  enable: true,
  type: 'session',  // Enable session tracking
});

configureAutoTrack({
  enable: true,
  type: 'pageView',  // Enable page view tracking
  getUrl: () => window.location.href,  // Customize URL if needed
});

configureAutoTrack({
  enable: true,
  type: 'event',  // Enable event tracking
  events: ['click', 'scroll', 'mousemove'],  // Track additional events
  selectorPrefix: 'body',  // Track events within the body element
});

// Manual tracking example for link clicks
document.querySelectorAll('a').forEach(anchor => {
  anchor.addEventListener('click', () => {
    record({
      name: 'LinkClick',
      attributes: {
        href: anchor.href,
        text: anchor.innerText,
      },
    });
  });
});






const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
