import React from 'react';

const About = () => {
  return (
    <section id="about" className="py-20 bg-gray-100">
      <div className="container mx-auto px-4">
        <h2 className="text-3xl font-bold text-center mb-12">Sobre Nós</h2>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-12">
          <div className="bg-white p-6 rounded-lg shadow-lg text-center">
            <h3 className="text-xl font-semibold mb-4">Missão</h3>
            <p className="text-gray-700">
              Construir empreendimentos com qualidade que agregam valor para a empresa e clientes, com foco na inovação de produtos e serviços.
            </p>
          </div>
          <div className="bg-white p-6 rounded-lg shadow-lg text-center">
            <h3 className="text-xl font-semibold mb-4">Visão</h3>
            <p className="text-gray-700">
              Ser reconhecida no mercado imobiliário como empresa sólida e sustentável, conquistando a confiança e a preferência dos clientes.
            </p>
          </div>
          <div className="bg-white p-6 rounded-lg shadow-lg text-center">
            <h3 className="text-xl font-semibold mb-4">Valores</h3>
            <ul className="text-gray-700 text-left">
              <li className="mb-2">• Foco na satisfação do cliente</li>
              <li className="mb-2">• Respeito ao meio ambiente e sociedade</li>
              <li className="mb-2">• Ética e transparência</li>
              <li className="mb-2">• Qualidade e pontualidade</li>
              <li className="mb-2">• Investimento em inovações</li>
              <li className="mb-2">• Valorização das pessoas</li>
            </ul>
          </div>
        </div>
      </div>
    </section>
  );
}

export default About;
